<template>
    <div class="body">
        <div class="container" v-if="exam">
            <h2 class="title">{{exam.title}}</h2>
            <div class="date light-color">
                {{exam.start_time}} ～ {{exam.end_time}}
            </div>
            <div class="section-box">
                <div class="item">
                    <img src="../../assets/images/timer.png">
                    <div class="item-title light-color">
                        答题时长
                    </div>
                    <div class="item-desc">
                        {{exam.minute}}分钟
                    </div>
                </div>
                <div class="item">
                    <img src="../../assets/images/manfen.png">
                    <div class="item-title light-color">
                        考试总分
                    </div>
                    <div class="item-desc">
                        {{exam.total}}
                    </div>
                </div>
                <div class="item">
                    <img src="../../assets/images/jige.png">
                    <div class="item-title light-color">
                        及格分数
                    </div>
                    <div class="item-desc">
                        {{exam.pass}}
                    </div>
                </div>
                <!-- <div class="item">
                    <img src="../assets/images/jige.png">
                    <div class="item-title">
                        及格分数
                    </div>
                    <div class="item-desc">
                        60分钟
                    </div>
                </div> -->
            </div>
            <div class="separator-line full-width"></div>
            <div class="content">
                <h2 class="title">考试说明</h2>
                <p class="light-color">{{exam.description}}</p>
            </div>
        </div>
        <div class="footer">
            <van-button type="info" @click="toQuestion">开始考试</van-button>
        </div>
    </div>
</template>

<script>
    import { getExam } from '../../api/task.js';
    import { checkLogin } from '@/utils/common.js';
    import { mapState } from 'vuex'
    var examid, tid,type;
     export default {
         activated() {
             examid = this.$route.query.examid; // 课程id
             tid = this.$route.query.tid; // 任务ID，用于计算进度，并更新总进度 
             type = this.$route.query.type; // 区分课件还是任务，课件是c;
             checkLogin().then(()=> {
                 this.getExam();
             })
             this.hideTab();
         },
         data() {
           return {
               exam: null,
           }  
         },
         computed: mapState(['token']),
         methods:{
             toQuestion(){
                 this.$router.replace(`/question?tid=${tid}&examid=${examid}&type=${type}`);
             },
            getExam(){
                 getExam(examid, tid).then((ret)=>{
                     if(ret){
                         this.exam = ret.data;
                     }
                 })
             },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            }
         }
     }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .body{
        width: 100vw;
        height: 100vh;
        background-color: #fff;
    }
    .container{
        color: #000;
    }
    .title{
        font-weight: ;
    }
    .date{
        color: #666;
        
    }
    .section-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 2rem;
        
    }
    .item{
        width: 10.7rem;
        height: 10rem;
        background:#f7f8fa;
        border-radius:5px;
    }
    .item *{
        padding: 1px 15px;
    }
    .item img{
        width: 33px;
        padding-top: 10px;
    }
    .item-title{
        /* font-size: 1.4rem; */
        color: #666;
    }
    .item-desc{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .separator-line{
        height: 14px;
        background-color: #f2f2f2;
        margin: 17px 0 23px auto;
    }
    .content p{
        padding-bottom: 92px;
    }
    .footer{
        position: fixed;
        width: 100vw;
        height: 4.5rem;
        bottom: 0;
        border-top: 1px solid #e8e8e8;
    }
    .footer button{
        margin-top: 0.5rem;
        height: 3.5rem;
        width: 34.5rem;
        line-height: 3.5rem;
        font-size: 1.4rem;
        background-color: #465ecc;
        margin-left: 1.5rem;
    }
</style>